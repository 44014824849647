import { useEffect, useState } from 'react'

import {
    Box,
    Typography,
    Divider,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import { POSCard, POSHeaderStepper, POSVerifyRouteAccess } from 'src/components'

import {
    useMainContext,
    useTrackerContext,
    IResult,
    useCreatePurchaseV2,
    useQuery,
} from 'src/hooks'

import { formatCurrency, onlyNumbers } from 'src/utils'

import { paths } from 'src/config/routes-paths'

import { logError } from 'src/config/log-error'

import { PaymentMethodType } from 'src/types'

import { BoxInfo } from './BoxInfo'
import { Contract, SubmitContract } from './Contract'
import { createCardToken } from 'src/utils/create-new-payment-profile'
import { ICredCardParams } from 'src/utils/types/credit-card'
import { useHubspot } from 'src/hooks/use-hubspot'

const getValueOr = (value: any, defaultValue: string | number = '') =>
    value || defaultValue

const getLastFourDigits = (digits = '') => digits.substr(digits.length - 4)

export enum ErrorPurchase {
    INVALID_CUSTOMER_INFO = 'INVALID_CUSTOMER_INFO',
    INVALID_PAYMENT_INFO = 'INVALID_PAYMENT_INFO',
    INVALID_COUPON = 'INVALID_COUPON',
    FAILED_PAYMENT_PROFILE = 'FAILED_PAYMENT_PROFILE',
    FAILED_CUSTOMER_PROFILE = 'FAILED_CUSTOMER_PROFILE',
    MISMATCH_PRODUCT = 'MISMATCH_PRODUCT',
    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
    INVALID_CARD_BRAND = 'INVALID_CARD_BRAND',
}

const getErrorStep = (error: ErrorPurchase) => {
    switch (error) {
        case ErrorPurchase.MISMATCH_PRODUCT:
        case ErrorPurchase.INTERNAL_SERVER_ERROR:
            return paths.initial
        case ErrorPurchase.INVALID_CUSTOMER_INFO:
        case ErrorPurchase.FAILED_CUSTOMER_PROFILE:
            return paths['personal-data']
        case ErrorPurchase.INVALID_PAYMENT_INFO:
        case ErrorPurchase.INVALID_COUPON:
        case ErrorPurchase.FAILED_PAYMENT_PROFILE:
        case ErrorPurchase.INVALID_CARD_BRAND:
            return paths.payment
        default:
            return paths.initial
    }
}

const Review = () => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    const history = useHistory()

    const query = useQuery()
    const { sendPaymentForm } = useHubspot(query)

    const [isSubmitted, setSubmitted] = useState(false)

    const {
        buyer,
        productSelected,
        data,
        setBuyer,
        goToPayment,
        contract,
        isPromoEnabled,
        isRecurrenceEnabled,
        isBankSlipEnabled,
        totalValueWithCoupon,
        installmentValueWithCoupon,
    } = useMainContext()

    const { mutateAsync: createPurchaseV2 } = useCreatePurchaseV2()

    const installments = getValueOr(productSelected?.totalInstallments, 1)
    const billingDay =
        !!buyer?.billingDay && buyer?.billingDay !== ''
            ? Number(buyer?.billingDay)
            : undefined

    const totalPriceFormatted = data.coupon?.promoCode
        ? formatCurrency.format(totalValueWithCoupon || 0)
        : formatCurrency.format(productSelected?.totalValueToPay || 0)

    const goToErrorPage = (error: ErrorPurchase) => {
        const stepError = getErrorStep(error)
        history.push({
            pathname: `./${paths.error}`,
            search: history.location.search
                ? history.location.search + `&step=${stepError}`
                : `?step=${stepError}`,
        })
    }

    const { track } = useTrackerContext()

    useEffect(() => {
        if (!!data?.id) {
            track({
                eventName: 'Purchase summary page viewed',
                additionalAttrs: {
                    'Payment method': productSelected?.customDescription,
                    'Course name': data?.name,
                    'Course duration': `${data?.duration} meses`,
                },
            })
        }
    }, [
        track,
        data?.name,
        data?.id,
        data?.duration,
        productSelected?.customDescription,
    ])

    const handleBack = () => {
        track({
            eventName: 'Review purchase CTA clicked',
            additionalAttrs: {
                'Payment method': productSelected?.customDescription,
                'Course name': data?.name,
                'Course duration': `${data?.duration} meses`,
            },
        })
        goToPayment()
    }

    const goToSuccessPage = (data: IResult) => {
        if (buyer?.paymentMethod === PaymentMethodType.BANK_SLIP) {
            const params = new URLSearchParams(history.location.search)

            params.append('code', data?.typableBarcode)
            params.append('url', data?.bankSlipUrl)

            history.push({
                pathname: `./${paths.success}`,
                search: `?${params.toString()}`,
            })
        } else {
            history.push(`./${paths.success}` + history.location.search)
        }
    }

    const getCardToken = async (
        createCardToken: (creditCard: ICredCardParams) => Promise<string>
    ): Promise<string> => {
        try {
            return await createCardToken({
                card_cvv: buyer?.creditCard?.cvv!,
                card_expiration: buyer?.creditCard?.expirationDate!,
                card_number: buyer?.creditCard?.creditCard!,
                holder_name: buyer?.creditCard?.name!,
                payment_company_code: buyer?.creditCard?.creditCardBrand!,
            })
        } catch (error: any) {
            logError(new Error('FAILED_CREATE_PAYMENT_PROFILE'), {
                message: error?.message,
            })

            goToErrorPage(ErrorPurchase.INVALID_CARD_BRAND)
        }

        return ''
    }

    const successPurchase = (result: IResult) => {
        setSubmitted(true)

        setBuyer((prev) => ({
            phone: prev?.phone,
            email: prev?.email,
            paymentMethod: prev?.paymentMethod,
            rg: buyer?.address?.rg!,
            birthdate: buyer?.address?.birthdate!,
            gender: buyer?.address?.gender!,
            countryCode: buyer?.countryCode!,
        }))

        sendPaymentForm({
            email: buyer?.email!,
            course: data.name,
            endDate: data.courseClasses[0].endDate.toString(),
            startDate: data.courseClasses[0].startDate.toString(),
            paymentMethod:
                buyer?.paymentMethod === PaymentMethodType.BANK_SLIP
                    ? 'Boleto'
                    : 'Cartão de crédito',
            address: `Rua ${buyer?.address?.street}, N ${buyer?.address?.number}, Bairro ${buyer?.address?.neighborhood}. Complemento: ${buyer?.address?.complement}`,
            city: buyer?.address?.city!,
            zip: buyer?.address?.zipCode!,
            state: buyer?.address?.state!,
            amount: productSelected?.totalValueToPay.toString() || '',
            class: data.courseClasses[0].name,
            cpf: buyer?.cpf!,
        })

        goToSuccessPage(result)
    }

    const failPurchase = (response: any) => {
        setSubmitted(true)

        const { data } = response

        setBuyer((prev) => ({
            ...prev,
            creditCard: undefined,
            cpf: undefined,
        }))

        logError(new Error(`FAILED_CREATE_PURCHASE`), {
            message: data?.message,
            error: data?.error,
            productId: productSelected?.id!,
            promoCode: data?.coupon?.promoCode!,
            paymentMethod: buyer?.paymentMethod!,
            status: response?.status,
        })

        goToErrorPage(data?.error || ErrorPurchase.INTERNAL_SERVER_ERROR)
    }

    const submitPayments: SubmitContract = async ({ acceptTerms }) => {
        let cardToken = ''

        if (buyer?.paymentMethod === PaymentMethodType.CREDIT_CARD) {
            cardToken = await getCardToken(createCardToken)
            if (cardToken === '') return
        }
        try {
            const result = await createPurchaseV2({
                acceptTerms: !!acceptTerms,
                user: {
                    name: buyer?.name!,
                    email: buyer?.email!,
                    phone: {
                        number: onlyNumbers(buyer?.phone!),
                        countryCode: buyer?.countryCode ?? '+55',
                    },
                    cpf: onlyNumbers(buyer?.cpf!),
                    rg: buyer?.address?.rg!,
                    birthdate: buyer?.address?.birthdate!,
                    gender: buyer?.address?.gender!,
                    address: {
                        country: 'BR',
                        state: buyer?.address?.state!,
                        city: buyer?.address?.city!,
                        neighborhood: buyer?.address?.neighborhood!,
                        street: buyer?.address?.street!,
                        zipCode: onlyNumbers(buyer?.address?.zipCode!),
                        number: String(buyer?.address?.number),
                        complement: buyer?.address?.complement,
                    },
                },
                paymentMethod: buyer?.paymentMethod!,
                paymentToken: cardToken,
                planSlug: productSelected!.planSlug!,
                promoCode: data?.coupon?.promoCode!,
                productSlug: data?.courseClasses[0].productSlug!,
                contractId: contract!.id,
                billingDay,
            })

            successPurchase(result)
        } catch (e: any) {
            failPurchase(e.response)
        }
    }

    const billingDayInfo = billingDay
        ? [
              {
                  label: 'Dia da cobrança (a partir da próxima cobrança)',
                  value: String(billingDay),
              },
          ]
        : []

    return (
        <Box mb={{ xs: 8, md: 12 }}>
            {!isSubmitted && (
                <POSVerifyRouteAccess
                    condition={
                        !buyer?.address ||
                        !buyer.name ||
                        !buyer.email ||
                        !buyer.phone ||
                        !buyer.paymentMethod ||
                        !buyer.cpf
                    }
                />
            )}

            <POSHeaderStepper courseName={data?.name || ''} activeStep={3} />

            <Typography variant="body1" paragraph color="textSecondary">
                Revise sua compra:
            </Typography>
            <POSCard p={{ xs: 2, md: 4 }}>
                <POSHeaderStepper courseName={data?.name || ''} size="small" />
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    mt={4}
                >
                    <BoxInfo
                        title="Dados Pessoais"
                        infos={[
                            {
                                label: 'Nome',
                                value: getValueOr(buyer?.name),
                            },
                            {
                                label: 'E-mail',
                                value: getValueOr(buyer?.email),
                            },
                            {
                                label: 'Telefone celular',
                                value: `${getValueOr(
                                    buyer?.countryCode
                                )} ${getValueOr(buyer?.phone)}`,
                            },
                        ]}
                    />
                    <Box mx={{ xs: 0, md: 8 }} my={{ xs: 2, md: 0 }}>
                        <Divider
                            orientation={matches ? 'horizontal' : 'vertical'}
                        />
                    </Box>
                    <BoxInfo
                        title="Endereço"
                        infos={[
                            {
                                label: 'CEP',
                                value: getValueOr(buyer?.address?.zipCode),
                            },
                            {
                                label: 'Endereço',
                                value: getValueOr(buyer?.address?.street),
                            },
                            {
                                label: 'Número',
                                value: getValueOr(buyer?.address?.number),
                            },
                            {
                                label: 'Complemento',
                                value: getValueOr(buyer?.address?.complement),
                            },
                            {
                                label: 'Bairro',
                                value: getValueOr(buyer?.address?.neighborhood),
                            },
                            {
                                label: 'Cidade',
                                value: getValueOr(buyer?.address?.city),
                            },
                            {
                                label: 'Estado',
                                value: getValueOr(buyer?.address?.state),
                            },
                        ]}
                    />
                    <Box mx={{ xs: 0, md: 8 }} my={{ xs: 2, md: 0 }}>
                        <Divider
                            orientation={matches ? 'horizontal' : 'vertical'}
                        />
                    </Box>

                    <Box flex={1}>
                        {buyer?.paymentMethod ===
                        PaymentMethodType.CREDIT_CARD ? (
                            <BoxInfo
                                title="Dados de Pagamento"
                                infos={[
                                    {
                                        label: 'Cartão de Crédito',
                                        value: `xxxx.xxxx.xxxx.${getLastFourDigits(
                                            buyer?.creditCard?.creditCard
                                        )}`,
                                    },
                                    {
                                        label: 'Bandeira do Cartão',
                                        value: getValueOr(
                                            buyer?.creditCard?.creditCardBrand
                                        ),
                                    },
                                    {
                                        label: 'Nome do titular',
                                        value: getValueOr(
                                            buyer?.creditCard?.name
                                        ),
                                    },
                                    {
                                        label: 'Vencimento',
                                        value: getValueOr(
                                            buyer?.creditCard?.expirationDate
                                        ),
                                    },
                                    ...billingDayInfo,
                                ]}
                                mb={4}
                            />
                        ) : (
                            <BoxInfo
                                title="Dados de Pagamento"
                                infos={[
                                    {
                                        label: 'Boleto Bancário',
                                    },
                                    {
                                        label: 'Parcelas',
                                        value: String(installments),
                                    },
                                    ...billingDayInfo,
                                ]}
                                mb={4}
                            />
                        )}

                        <BoxInfo
                            title="Seu Investimento"
                            infos={[
                                {
                                    label: 'Total',
                                    value: totalPriceFormatted,
                                },
                            ]}
                        >
                            {!isBankSlipEnabled &&
                                isPromoEnabled &&
                                productSelected?.priceFirstInstallment &&
                                productSelected?.paymentMethod ===
                                    PaymentMethodType.CREDIT_CARD && (
                                    <Box display={'flex'}>
                                        <Typography
                                            style={{
                                                color: '#00297B',
                                                fontSize: '16px',
                                                fontWeight: 700,
                                            }}
                                        >
                                            {`1ª parcela de `}
                                        </Typography>
                                        &nbsp;
                                        <Typography
                                            style={{
                                                color: '#2CAA6E',
                                                fontSize: '16px',
                                                fontWeight: 700,
                                            }}
                                        >
                                            {` ${formatCurrency.format(
                                                productSelected?.priceFirstInstallment
                                            )} + `}
                                        </Typography>
                                    </Box>
                                )}

                            <Typography
                                color="secondary"
                                variant="h6"
                                style={{ fontWeight: 700 }}
                            >
                                {productSelected &&
                                    `${
                                        isPromoEnabled &&
                                        !productSelected.isFirstChargeReductionCampaign &&
                                        !isRecurrenceEnabled &&
                                        productSelected.totalInstallments > 1 &&
                                        productSelected.paymentMethod ===
                                            PaymentMethodType.CREDIT_CARD
                                            ? productSelected.totalInstallments -
                                              1
                                            : productSelected.totalInstallments
                                    }x ${
                                        data.coupon?.promoCode
                                            ? formatCurrency.format(
                                                  installmentValueWithCoupon ||
                                                      0
                                              )
                                            : formatCurrency.format(
                                                  productSelected.installmentPrice
                                              )
                                    }`}
                            </Typography>
                        </BoxInfo>
                    </Box>
                </Box>
            </POSCard>

            <Contract onSubmit={submitPayments} onBack={handleBack} />
        </Box>
    )
}

export default Review
