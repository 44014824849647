import { HubspotFormField } from '@sanardigital/hubspot-client/dist/main/interfaces'
import {
    getCampaignFields,
    legalConsentOptions,
    transformCourseName,
} from './hubspot.helper'
import { env } from '../config/enviroments'
import { logError } from '../config/log-error'
import { format } from 'date-fns'
import { submitHubspotForm } from '@sanardigital/hubspot-client'
import { onlyNumbers } from 'src/utils'

interface RegistrationFields {
    name: string
    email: string
    phone: string
    course: string
}

interface PaymentFields {
    email: string
    address: string
    city: string
    state: string
    zip: string
    startDate: string
    endDate: string
    course: string
    paymentMethod: string
    amount: string
    class: string
    cpf: string
}

const PAGE_NAME = 'Pos Checkout'

export const useHubspot = (query: URLSearchParams) => {
    const submitForm = async (fields: HubspotFormField[], formId: string) => {
        if (env['ENVIRONMENT'] === 'prod' || env['ENABLE_HUBSPOT'] === 'true') {
            try {
                const campaingFields = getCampaignFields(query)

                const response = await submitHubspotForm(
                    {
                        fields: [...fields, ...campaingFields],
                        legalConsentOptions,
                        context: {
                            pageName: PAGE_NAME,
                            pageUri: `${window.location.origin}${window.location.pathname}`,
                        },
                    },
                    formId,
                    env['HUBSPOT_PORTAL_ID']
                )

                if (!response.success) {
                    if (!response.errors) throw new Error('Hubspot Error')

                    response.errors.forEach((err: any) => {
                        logError(err.message, {
                            error: `Hubspot form submition failed for formId ${formId}`,
                            errorType: err.errorType,
                        })
                    })
                }
            } catch (err) {
                logError(err, {
                    error: `Failed on submit form ${formId}`,
                })
            }
        }
    }

    const sendPersonalForm = async (
        formFields: RegistrationFields
    ): Promise<void> => {
        await submitForm(
            [
                { name: 'business_unit', value: 'posgraduacao' },
                { name: 'fullname', value: formFields.name },
                { name: 'email', value: formFields.email },
                {
                    name: 'phone',
                    value: onlyNumbers(formFields.phone),
                },
                {
                    name: 'contact__pos__product',
                    value: transformCourseName(formFields.course),
                },
                {
                    name: 'contact__pg__origin_channel',
                    value: 'Carrinho',
                },
                {
                    name: 'contact__gn__not_create_deal',
                    value: 'true',
                },
                {
                    name: 'contact__pos__bait',
                    value: 'Check-out pós graduação',
                },
            ],
            env['HUBSPOT_REGISTRATION_FORM_ID']
        )
    }

    const sendPaymentForm = async (
        formFields: PaymentFields
    ): Promise<void> => {
        await submitForm(
            [
                { name: 'email', value: formFields.email },
                {
                    name: 'contact__gn__payment_type',
                    value: formFields.paymentMethod,
                },
                {
                    name: 'address',
                    value: formFields.address,
                },
                {
                    name: 'zip',
                    value: formFields.zip,
                },
                {
                    name: 'state',
                    value: formFields.state,
                },
                {
                    name: 'city',
                    value: formFields.city,
                },
                {
                    name: 'contact__pg__course_start_date',
                    value: format(new Date(formFields.startDate), 'dd/MM/yyyy'),
                },
                {
                    name: 'contact__pg__course_finish_date',
                    value: format(new Date(formFields.endDate), 'dd/MM/yyyy'),
                },
                {
                    name: 'contact__pg__bought_course',
                    value: transformCourseName(formFields.course),
                },
                {
                    name: 'contact__pg__value_paid_integration',
                    value: formFields.amount,
                },
                {
                    name: 'contact__gn__not_create_deal',
                    value: 'true',
                },
                {
                    name: 'contact__pg__class',
                    value: formFields.class,
                },
                {
                    name: 'contact__gn__cpf',
                    value: onlyNumbers(formFields.cpf),
                },
            ],
            env['HUBSPOT_PAYMENT_FORM_ID']
        )
    }

    return { sendPersonalForm, sendPaymentForm }
}
